import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PME = () => {
    return (
        <Layout>
            <Seo
                title="Small Businesses - BIKESAFE"
                description="Providing secure bike parking for your customers or employees can potentially increase your business. Cyclist and non-cyclist will appreciate your “green” thinking when it comes to providing secure bike parking. The sheer ease of a quick trip on a bike to your store or facility is sure to gain loyal customers."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Small Businesses
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Good for Business (and the Earth)</h3>
                        <p className="flow-text">
                            With the rising cost of fuel and the attention to cutting harmful emissions, more and more people use their bikes to get around, supplying convenient bike parking is a smart business decision.
                        </p>
                        <p className="flow-text">
                            Providing secure bike parking for your customers or employees can potentially increase your business. Cyclist and non-cyclist will appreciate your “green” thinking when it comes to providing secure bike parking. The sheer ease of a quick trip on a bike to your store or facility is sure to gain loyal customers.
                        </p>
                        <p className="flow-text">
                            Clearly defined bike parking avoids conflict with pedestrians and parked vehicles, helping to keep bikes off of railings and signposts that might otherwise impede normal shop access and pedestrian traffic.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/pme.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"The new layout is working very well. Thank you for your help."
                                <strong> Simon Flack, Cycleops (Shop Owner), Kent</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PME;